@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@import "assets/lib/fontawesome6.3/css/all.css";

@tailwind base;
@tailwind components;
@tailwind utilities;


html, 
body {
  padding: 0;
  margin: 0;
  font-family: "open sans", sans-serif;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
.remove-arrow::-webkit-inner-spin-button,
.remove-arrow::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.ride-item:hover{background-color: #564DF5; transform: translateY(-10%);}
.ride-item:hover .ride-item-title{color: #fff;}
.ride-item:hover .ride-item-icon{background-color: #EDEDED; outline-color: #fff; color: #564DF5;}



.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.accordion {
  border: 1px solid #ccc;
}

.accordion-item {
  border-bottom: 1px solid #ccc;
}

.accordion-item-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  background-color: #f0f0f0;
}

.accordion-item-content {
  padding: 10px;
}

.signin-img-content .swiper-slide{ background-color: transparent;}
.signin-img-content .swiper-pagination-clickable .swiper-pagination-bullet{width: 16px; height: 16px; border-radius: 50px;}
